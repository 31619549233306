import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import styles from '../../assets/scss/modules/landing.module.scss';
import combined_shape from "../../assets/media/images/combined-shape.png";
import back from "../../assets/media/newicons/back.png";
import arrow from "../../assets/media/newicons/arrow.png";
import arrowWhite from "../../assets/media/newicons/arrowWhite.png";
import bike_1 from "../../assets/media/images/Bike 1.png";
import bike_2 from "../../assets/media/images/Bike 2.png";
import bike_3 from "../../assets/media/images/Bike 3.png";
import rent_e_bike from "../../assets/media/images/rent e-bike from.png";
import blick_logo from "../../assets/media/images/Blick Logo.png";
import bring_back from "../../assets/media/images/bring back until.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBView
} from 'mdb-react-ui-kit';

class BikeTyp extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
    return (
      <>
          <MDBRow className="pt-5" style={{width: "70%"}}>
            <MDBCol middle md="4" className="text-left p-0">
              <img className="rent-e-bike" src={rent_e_bike}/>
            </MDBCol>
            <MDBCol middle md="2" className="text-center p-0">
              <img className="blitz-logo" src={blick_logo}/>
            </MDBCol>
            <MDBCol middle md="4" className="text-left p-0">
              <img className="bring-back" src={bring_back}/>
            </MDBCol>
          </MDBRow>
      </>
    );
  }
}
export default  BikeTyp;
