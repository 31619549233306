import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './assets/scss/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ownStyleCSSUrl } from './env';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Laden...</div>}>
      <App/>
    </Suspense>
  </React.StrictMode>
  ,
  document.getElementById('root'));
document.getElementById('own_style_css_link').setAttribute("href", ownStyleCSSUrl());
reportWebVitals();
