import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import styles from '../assets/scss/modules/landing.module.scss';
import tesla_fantom from "../assets/media/images/Tesla-Fantom.png";
import tesla_mask from "../assets/media/images/Tesla-Mask.png";
import combined_shape from "../assets/media/images/combined-shape.png";
import back from "../assets/media/newicons/back.png";
import arrow from "../assets/media/newicons/arrow.png";
import arrowWhite from "../assets/media/newicons/arrowWhite.png";
import Cookies from 'universal-cookie';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../components/Footer/Footer';
import BikeTyp from "../components/Bikes/BikeTyp";
import ClickNRideAxios from '../Service/ClickNRideAxios';
import Moment from 'moment';
import 'moment/locale/de';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBDatepicker,
	MDBTimepicker
} from 'mdb-react-ui-kit';
import axios from 'axios';
const cookies = new Cookies();

class Scout extends Component {
	constructor(props) {
		super(props);
		let prevDate = Moment(Date.now());
		this.state = {valueRent: prevDate.format('DD-MM-YYYY'),
									valueBring: prevDate.format('DD-MM-YYYY'),
									};
		this.interAxios = new ClickNRideAxios(this);
	};

	componentDidMount() {
	}

	componentWillUnmount() {
		this.interAxios.cancel();
	}


	handleChangeRent = (value, date) => {
		var date2 = date;
		date2.setDate(date2.getDate() - 1);
		this.setState({valueRent: value, dateRent: date, valueBring: value, dateBring: date2});
	}

	handleChangeBring = (value) => {
		this.setState({valueBring: value});
	}
	getPickerValueRent = (value) => {
		this.setState({valueTimeRent: value});
	}
	getPickerValueBring = (value) => {
		this.setState({valueTimeBring: value});
	}




	render() {
		var date = new Date();
		date.setDate(date.getDate() - 1);

		return (
				<>
				<div className={styles.bodywrapper8}>
				<MDBContainer style={{maxWidth: "1430px"}}>
					<BikeTyp/>
					<div className="datetime-form">
						<MDBRow className="pt-5" style={{width: "70%"}}>
							<MDBCol middle md="4" className="text-left p-0">
								<MDBRow>
									<MDBCol md="7" middle>
										<div className="date-form">
											<MDBDatepicker inline  format='dd-mm-yyyy' min={date} icon='fa-2x far fa-calendar-alt' value={this.state.valueRent}  onChange={this.handleChangeRent}/>
										</div>
									</MDBCol>
									<MDBCol md="5" middle>
										<div className="time-form">
											<MDBTimepicker customIcon='fa-2x far fa-clock' customValue="9:00 AM"/>
										</div>
									</MDBCol>
								</MDBRow>

							</MDBCol>
							<MDBCol middle md="2" className="text-center ps-0">
							</MDBCol>
							<MDBCol middle md="4" className="text-left p-0">
								<MDBRow>
									<MDBCol md="7" middle>
										<div className="date-form">
											<MDBDatepicker inline  format='dd-mm-yyyy' min={this.state.dateBring !== undefined ? this.state.dateBring : new Date()} value={this.state.valueBring} icon='fa-2x far fa-calendar-alt'/>
										</div>
									</MDBCol>
									<MDBCol md="5" middle>
										<div className="time-form">
											<MDBTimepicker customIcon='fa-2x far fa-clock' customValue="9:00 AM"/>
										</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</div>
				</MDBContainer>
				</div>
				</>
		);
	}
	}
export default Scout;
