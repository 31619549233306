import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import ClickNRideAxios from '../../Service/ClickNRideAxios';

import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';


class BookingModal extends Component {

  constructor(props) {
    super(props);
    this.interAxios = new ClickNRideAxios(this);
    this.state = {bookingStatus: false
                };
  };

  componentDidMount() {

  };

  componentWillUnmount() {
  }

  setBasicModal = () => {
    this.setState({basicmodal: !this.props.basicModal})
  }

  setBookingStatus = () => {
    this.setState({bookingStatus: !this.state.bookingStatus});
  }


  render() {

    return (
      <>
      <MDBModal show={this.props.basicModal} tabIndex='-1'>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <MDBRow className="ps-5 py-5">
                {!this.state.bookingStatus ? <>
                  <MDBCol md="auto" className="d-flex align-items-center pe-1">
                    <div className="details-btn">
                      <span>Details</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="middle-orange"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="small-orange"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="middle-gray"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center ps-1">
                    <div className="booking-btn">
                      <span>Booking</span>
                    </div>
                  </MDBCol>
                  </>:<>
                  <MDBCol md="auto" className="d-flex align-items-center pe-1">
                    <div className="booking-btn">
                      <span>Details</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="middle-gray"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="small-orange"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center px-1">
                    <div className="middle-orange"/>
                  </MDBCol>
                  <MDBCol md="auto" className="d-flex align-items-center ps-1">
                    <div className="details-btn">
                      <span>Booking</span>
                    </div>
                  </MDBCol></>}
                </MDBRow>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={this.props.setBasicModal}></MDBBtn>
            </MDBModalHeader>



            <MDBModalBody>


            </MDBModalBody>




            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={this.props.setBasicModal}>
                Close
              </MDBBtn>
              <MDBBtn>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </>
    );
  }
}
export default BookingModal;
