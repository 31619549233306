import React, { Component } from "react";
import logo_w from '../../assets/media/newicons/Group 6.png';
import{
	MDBRow,
	MDBCol,
	MDBCard,
	MDBNav,
	MDBNavItem,
	MDBContainer,
} from 'mdb-react-ui-kit';

class Footer extends Component {

  constructor(props) {
    super(props);
  };

  render() {
    return (
			<div className="footer-div">
			<MDBContainer style={{maxWidth: "1430px"}}>
        <MDBRow center style={{height: "92px"}}>
          <MDBCol md="6" size="12" className="d-flex align-items-center">
					<div className="d-none d-md-block" >
					<MDBRow start>
            <img src={logo_w} className="navbar-logo-footer"/>
					</MDBRow>
					</div>
					<div className="d-md-none d-block">
					<MDBRow center className="py-4">
            <img src={logo_w} className="navbar-logo-footer"/>
					</MDBRow>
					</div>
          </MDBCol>
          <MDBCol md="6" size="12" className="d-flex align-items-center">
            <MDBRow style={{width: "100%"}}>
              <MDBCol md="4" size="12" className="text-center py-2">
                <span className="footer-text">ClickNRide 2022</span>
              </MDBCol>
              <MDBCol md="4" size="12" className="text-center py-2">
                <a className="footer-text" href="/">Privacy Policy</a>
              </MDBCol>
              <MDBCol md="4" size="12" className="text-center py-2">
                <a className="footer-text" href="/">Impressum</a>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
			</MDBContainer>
      </div>


    );
  }
}

export default Footer;
