import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {BrowserRouter as Router, Route } from 'react-router-dom';

import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNav,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBFormInline,
  MDBInputGroup,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBCard,
  MDBNavbarLink
} from 'mdb-react-ui-kit';

import logo from './assets/media/newicons/Logo_Bergfrei_250px-180w.png';
import logo_w from './assets/media/newicons/Logo_Bergfrei_250px-180w.png';


import Cookies from 'universal-cookie';
const cookies = new Cookies();

class NavbarComponent extends Component {

	constructor(props) {
    super(props);
    let urlstart = window.location.href;
    if(!urlstart.includes('Cross') && !urlstart.includes('Touring') && !urlstart.includes('City') && !urlstart.includes('Kids') && !urlstart.includes('Scout')){
      this.state = {
  								  activeItem: "2"
                  };
    }
    if(urlstart.includes('Cross')){
      this.state = {
  								  activeItem: "3"
                  };
    }
    if(urlstart.includes('Touring')){
      this.state = {
  								  activeItem: "4"
                  };
    }
    if(urlstart.includes('City')){
      this.state = {
  								  activeItem: "5"
                  };
    }
    if(urlstart.includes('Kids')){
      this.state = {
  								  activeItem: "6"
                  };
    }
    if(urlstart.includes('Scout')){
      this.state = {
  								  activeItem: "7"
                  };
    }

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  toggle = tab => e => {
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
	}
  var x = document.getElementById("toggler");
    if (window.getComputedStyle(x).display !== "none") {
      this.setState({ isOpen: !this.state.isOpen});
    }
}


  toggleCollapse = () => {
    var x = document.getElementById("toggler");
      if (window.getComputedStyle(x).display !== "none") {
        this.setState({ isOpen: !this.state.isOpen});
      }
  }

	render() {
    let url = window.location.href;
		return (
			<>
      <MDBNavbar expand='md' fixed='top' transparent >
      				<MDBContainer className="navbarcomponent-nav-container">
      					<MDBNavbarBrand href='/'>
      						<img src={!this.props.green ? logo : logo_w} className="d-none d-md-block"/>
                  <img src={logo} className="d-md-none d-block"/>
      					</MDBNavbarBrand>
      					<MDBNavbarToggler id="toggler" onClick={this.toggleCollapse} />
      					<MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar className="d-none d-md-block">

                  <MDBNavbarNav className="navbarcomponent-nav navcard">
                    <MDBNavbarItem active={!url.includes('Cross') && !url.includes('City') && !url.includes('Touring') && !url.includes('Kids') && !url.includes('Scout')}>
                    <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "2"}><Link to="/"  onClick={this.toggle("2")} role="tab" ><span className="navbar-text clicknride">ClickNRide</span></Link></MDBNavbarLink>
                    </div>
                    </MDBNavbarItem>
                    <MDBNavbarItem active={url.includes('Cross')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "3"}><Link to="/Cross"  onClick={this.toggle("3")} role="tab" ><span className="navbar-text cross">Cross</span></Link></MDBNavbarLink>
                      </div>
                    </MDBNavbarItem>
                    <MDBNavbarItem active={url.includes('City')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "4"}><Link to="/City"  onClick={this.toggle("4")} role="tab" ><span className="navbar-text city">City</span></Link></MDBNavbarLink>
                      </div>
                    </MDBNavbarItem>
                    <MDBNavbarItem active={url.includes('Touring')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "5"}><Link to="/Touring"  onClick={this.toggle("5")} role="tab" ><span className="navbar-text touring">Touring</span></Link></MDBNavbarLink>
                      </div>
                    </MDBNavbarItem>
                    <MDBNavbarItem active={url.includes('Kids')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "6"}><Link to="/Kids"  onClick={this.toggle("6")} role="tab" ><span className="navbar-text kids">Kids</span></Link></MDBNavbarLink>
                      </div>
                    </MDBNavbarItem>
                    <MDBNavbarItem active={url.includes('Scout')}>
                      <div className={!this.props.green ? "navbar-notgreen" : "navbar-green"}>
                      <MDBNavbarLink active={this.state.activeItem === "7"}><Link to="/Scout"  onClick={this.toggle("7")} role="tab" ><span className="navbar-text scout">Scout</span></Link></MDBNavbarLink>
                      </div>
                    </MDBNavbarItem>



      						</MDBNavbarNav>

      					</MDBCollapse>
                <div className="phonenumber text-center d-none d-md-block">
                <span className="phonenumber-text">+49 174 344 96 16</span>
                </div>
      					</MDBContainer>

      				</MDBNavbar>






</>
		);
	}
}

export default NavbarComponent;
