import React, { Component } from "react";
import { Link } from 'react-router-dom'
import styles from '../../assets/scss/modules/landing.module.scss';
import combined_shape from "../../assets/media/images/combined-shape.png";
import back from "../../assets/media/newicons/back.png";
import arrow from "../../assets/media/newicons/arrow.png";
import arrowWhite from "../../assets/media/newicons/arrowWhite.png";
import bike_1 from "../../assets/media/images/Bike 1.png";
import bike_2 from "../../assets/media/images/Bike 2.png";
import bike_3 from "../../assets/media/images/Bike 3.png";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBView
} from 'mdb-react-ui-kit';

class BikeAnzeige extends Component {

	constructor(props) {
    super(props);
    this.state = {
									};
  };


  render() {
    return (
      <>
      <MDBRow center className="m-0">
        <img className="bike-picture" src={bike_1}/>
      </MDBRow>
      <MDBRow className="py-3 m-0">
        <MDBCol md="6" middle className="text-left">
          <MDBRow>
            <span className="anzeige-text">Ghost Hybride ASX</span>
          </MDBRow>
          <MDBRow>
            <span className="anzeige-text">Base 130 44 RT</span>
          </MDBRow>
        </MDBCol>
        <MDBCol md="6" middle className="text-right">
          <MDBBtn flat onClick={this.props.toggleShow} className="book-btn"><span>Book</span></MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow className="pb-3 m-0">
        <MDBCol md="6" middle className="text-left">
          <MDBRow>
           <Link to="/Touring"><span className="link-text">{"More Touring bikes >"}</span></Link>
          </MDBRow>
        </MDBCol>
        <MDBCol md="6" middle className="text-right">

            <span className="price-text">15 € / h</span>

        </MDBCol>
      </MDBRow>
      </>

    );
  }
}
export default  BikeAnzeige;
