import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import cookiesicon from "./assets/media/newicons/cookiesicon.png";
import Cookies from 'universal-cookie';

import LandingPage from "./pages/LandingPage";
import Cross from "./pages/Cross";
import City from "./pages/City";
import Touring from "./pages/Touring";
import Kids from "./pages/Kids";
import Scout from "./pages/Scout";
import NavbarComponent from './NavbarComponent';

const cookies = new Cookies();

class App extends Component {
	state = {green: false
	};

  onCookieConsent = () => {
    cookies.set('CookieConsent', 'true', {
        path: '/'
    })
  }

	onGrennTrue = () => {
		this.setState({green: true});
	}

	onGrennFalse = () => {
		this.setState({green: false});
	}


	render() {
		return (
			<>


		<Router>

		          <NavbarComponent green = {this.state.green}/>

			          <Route path="/" exact>
			            <LandingPage {...this} {...this.props}/>
			          </Route>
			          <Route path="/Cross" exact>
			            <Cross {...this} {...this.props}/>
			          </Route>
			          <Route path="/City" exact>
			            <City {...this} {...this.props}/>
			          </Route>
			          <Route path="/Touring" exact>
			            <Touring {...this} {...this.props}/>
			          </Route>
			          <Route path="/Kids" exact>
			            <Kids {...this} {...this.props}/>
			          </Route>
			          <Route path="/Scout" exact>
			            <Scout {...this} {...this.props}/>
			          </Route>



		    </Router>

</>
		);
	}
}

export default App;
