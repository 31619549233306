import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom'
import styles from '../assets/scss/modules/landing.module.scss';
import landing_tesla from "../assets/media/images/landing-tesla.png";
import accumulator from "../assets/media/images/accumulator.png";
import atmosphere from "../assets/media/images/atmosphere.png";
import biofuel from "../assets/media/images/biofuel.png";
import battery from "../assets/media/images/battery.png";
import electric_car from "../assets/media/images/electric-car.png";
import electric_tower from "../assets/media/images/electric-tower.png";
import carE_1 from "../assets/media/images/carE-1.png";
import carE4 from "../assets/media/images/carE4.png";
import green_energy from "../assets/media/images/green-energy.png";
import bike from "../assets/media/images/bike.png";
import bike_1 from "../assets/media/images/Bike 1.png";
import bike_2 from "../assets/media/images/Bike 2.png";
import bike_3 from "../assets/media/images/Bike 3.png";
import wind from "../assets/media/images/wind.png";
import minta_akku from "../assets/media/images/minta-akku.png";
import minta_akku_w from "../assets/media/images/minta-akku-w.png";
import arrowWhite_down from "../assets/media/newicons/arrowWhite_down.png";
import arrowWhite_up from "../assets/media/newicons/arrowWhite_up.png";
import Cookies from 'universal-cookie';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../components/Footer/Footer';
import ClickNRideAxios from '../Service/ClickNRideAxios';
import BikeAnzeige from "../components/Bikes/BikeAnzeige";

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavItem,
	MDBNav,
	MDBFormInline,
	MDBIcon,
	MDBCollapseHeader,
	MDBCollapse,
} from 'mdb-react-ui-kit';
import axios from 'axios';

import { backendUrl } from '../env';

const cookies = new Cookies();

class LandingPage extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new ClickNRideAxios(this);
    this.state = {showModal: false,
			 						nev: "", telefonszam: "",
									automodelList1: [],
									automodelList2: [],
									automodelList3: [],
									automodelList4: [],
									gyikList: [],
									collapseID: -1
									};
  };

	componentDidMount() {
		this.handlegetTesla();
		this.handlegetAllGyik();
	}

	componentWillUnmount() {
		this.interAxios.cancel();
		this.props.onGrennFalse();
	}

	handlegetTesla = () => {
	 let self = this;
	 this.interAxios.get('/getautomodels',
		function(response) {

			let list = response.data
			let list1 = self.getRandom(list, 2);
			var res1 = list.filter(item1 =>
					!list1.some(item2 => (item2.id === item1.id)))
			let list2 = self.getRandom(res1, 2);
			var res2 = res1.filter(item1 =>
					!list2.some(item2 => (item2.id === item1.id)))
			let list3 = self.getRandom(res2, 2);
			var res3 = res2.filter(item1 =>
					!list3.some(item2 => (item2.id === item1.id)))
			let list4 = self.getRandom(res3, 2);
			self.setState({ automodelList1: list1,
											automodelList2: list2,
										  automodelList3: list3,
										  automodelList4: list4,})
		});
	}

	handlegetAllGyik = () => {
	 let self = this;
	 this.interAxios.get('/getallgyik',
		function(response) {
			self.setState({gyikList: response.data})
		});
	}

	handleNewGyik = (event) => {
	 let self = this;
	 event.preventDefault();
	 this.interAxios.post('/newgyik',
			 {kerdes: "Lorem Ipsum Dolor?",
				valasz: "Pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch."},
		function(response) {

		});
	}

	changeHandler = event => {
	    this.setState({ [event.target.name]: event.target.value });
	  };

	 handleSend = (event) => {
    let self = this;
		event.preventDefault();
    axios.post(process.env.REACT_APP_BACKEND_URL+'/send',
        {email: this.state.email,
         subject: this.state.subject,
         message: this.state.message},
     function(response) {

     });
	 }

	handleShow = (model, tesztvezetes) => {
		this.setState({teslamodel: model, showModal: !this.state.showModal, tesztvezetesmodal: tesztvezetes});
	}

	handleClose= () => {
		this.setState({showModal: !this.state.showModal});
	}

	toggleCollapse = collapseID => () =>
  this.setState(prevState => ({
    collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

	getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
	}

  render() {
		const { collapseID } = this.state;
		const anchors = ["firstPage", "secondPage", "thirdPage", "lastPage"];
		const navigationTooltips = ["HOME", "OUR SERVICE", "ABOUT US", "CONTACT"];

    return (
			<>

			<ReactFullpage
			    licenseKey = {'2C01A6F9-2F35483E-81C14CF4-C7FF7A52'}
			    scrollingSpeed = {500} /* Options here */
					anchors={anchors}
					menu='#myMenu'
					navigation={true}
					navigationTooltips={navigationTooltips}
					navigationPosition={'right'}
					showActiveTooltip= {true}
					responsiveWidth={800}
					responsiveHeight={750}
					onLeave={(origin,destination,direction) => {
						if(destination.index === 1){
							this.props.onGrennTrue();
						}else{
							this.props.onGrennFalse();
						}
					}}


    render={({ state, fullpageApi }) => {
      return (
				<>
        <ReactFullpage.Wrapper>
					<MDBContainer fluid className="section fluid-container">
						<div className={styles.bodywrapper}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow className="pb-5">
									<MDBCol size="12" className="ps-4 text-left" md="6" middle>
										<MDBRow start className="landing-e-fleet-row">
											<span className="e-fleet-management">ClickNRide</span>
										</MDBRow>
										<MDBRow start className="landing-e-fleet-row-second">
											<span className="e-fleet-management" style={{color: "#434343", }}>e-bike renting</span>
										</MDBRow>
									</MDBCol>
									<MDBCol md="6">
									</MDBCol>
								</MDBRow>
								<MDBRow>
									<MDBCol md="4" className="pe-5">
										<BikeAnzeige/>
									</MDBCol>
									<MDBCol md="4" className="px-3">
										<MDBRow center className="m-0">
											<img className="bike-picture" src={bike_2}/>
										</MDBRow>
										<MDBRow className="py-3 m-0">
											<MDBCol md="6" middle className="text-left">
												<MDBRow>
													<span className="anzeige-text">Ghost Hybride ASX</span>
												</MDBRow>
												<MDBRow>
													<span className="anzeige-text">Base 130 44 RT</span>
												</MDBRow>
											</MDBCol>
											<MDBCol md="6" middle className="text-right">
												<MDBBtn flat className="book-btn"><span>Book</span></MDBBtn>
											</MDBCol>
										</MDBRow>
										<MDBRow className="pb-3 m-0">
											<MDBCol md="6" middle className="text-left">
												<MDBRow>
												 <Link to="/Touring"><span className="link-text">{"More Touring bikes >"}</span></Link>
												</MDBRow>
											</MDBCol>
											<MDBCol md="6" middle className="text-right">

													<span className="price-text">12 € / h</span>

											</MDBCol>
										</MDBRow>
									</MDBCol>
									<MDBCol md="4"className="ps-5">
										<MDBRow center className="m-0">
											<img className="bike-picture" src={bike_3}/>
										</MDBRow>
										<MDBRow className="py-3 m-0">
											<MDBCol md="6" middle className="text-left">
												<MDBRow>
													<span className="anzeige-text">Ghost Hybride ASX</span>
												</MDBRow>
												<MDBRow>
													<span className="anzeige-text">Base 130 44 RT</span>
												</MDBRow>
											</MDBCol>
											<MDBCol md="6" middle className="text-right">
												<MDBBtn flat className="book-btn"><span>Book</span></MDBBtn>
											</MDBCol>
										</MDBRow>
										<MDBRow className="pb-3 m-0">
											<MDBCol md="6" middle className="text-left">
												<MDBRow>
												 <Link to="/Touring"><span className="link-text">{"More Touring bikes >"}</span></Link>
												</MDBRow>
											</MDBCol>
											<MDBCol md="6" middle className="text-right">

													<span className="price-text">11 € / h</span>

											</MDBCol>
										</MDBRow>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
					</MDBContainer>

					<MDBContainer fluid className="section fluid-container">
						<div className={styles.bodywrapper2}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow className="landing-section2-row">
									<MDBCol md="6" size="12" middle className="left-col">
										<MDBRow className="landing-rental-row pt-5">
											<span className="rental">Rental</span>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={accumulator} className="accumulator"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={atmosphere} className="atmosphere"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={biofuel} className="biofuel"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-w">Lorem ipsum dolor</span><br/>
											<span className="lorem-w-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>

									</MDBCol>
									<MDBCol md="6" size="12" middle className="right-col">
										<MDBRow className="landing-rental-row pt-5">
											<span className="services">Services</span>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2" size="4" middle className="landing-col-icon">
											<img src={electric_tower} className="electric-tower"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={electric_car} className="electric-car"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
										<MDBRow className="landing-symbol-icon">
											<MDBCol md="2"  size="4" middle className="landing-col-icon">
											<img src={battery} className="battery"/>
											</MDBCol>
											<MDBCol md="10" size="8" middle>
											<MDBRow className="landing-lorem-row">
											<span className="lorem-b">Lorem ipsum dolor</span><br/>
											<span className="lorem-b-t">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut turpis odio, mattis at hendrerit ac, molestie at velit. Nullam sodales tortor nec lacinia convallis. In vehicula lectus nec tellus tristique, vitae congue enim pellentesque.</span>
											</MDBRow>
											</MDBCol>
										</MDBRow>
									</MDBCol>
								</MDBRow>
							</MDBContainer>
						</div>
					</MDBContainer>

					<MDBContainer fluid className="section fluid-container  without-height">
						<div className="d-none d-md-block">
							<div className={styles.bodywrapper7}>
								<MDBContainer style={{maxWidth: "1430px"}}>
									<MDBRow center className="pt-3 pb-4">

									</MDBRow>
								</MDBContainer>
							</div>
						</div>
					</MDBContainer>

					<MDBContainer fluid className="section fluid-container  without-height">
						<div className="d-none d-md-block">
							<div className={styles.bodywrapper5}>
							<MDBContainer style={{maxWidth: "1430px"}}>
								<MDBRow>
											<form className="landing-email-form" onSubmit={this.handleSend}>
												<MDBRow className="landing-kapcsolat-row">
													<MDBCol middle md="6" size="12" className="landing-padding-right-kapcsolat">
														<p className="landing-email-kapcsolat">Contact</p>
													</MDBCol>
													<MDBCol middle md="6" className="ps-5 pt-5 d-none d-md-block">
														<p className="dijmentes-tanacsadas">We are available for free advice and test rides!</p>
													</MDBCol>
												</MDBRow>
												<MDBRow style={{maxWidth: "100%"}}>
												<MDBCol size="12" md="6" bottom className="landing-padding-right">
													<MDBRow className="landing-email-kapcsolat-email landing-form-email-row" >
														<label htmlFor="defaultFormContactEmailEx" className="grey-text">
															<span className="your-email-label">Your Email Address</span>
														</label>
														<input type="email" id="defaultFormContactEmailEx" name="email" className="form-control" onChange={this.changeHandler} value={this.state.email}/>
													</MDBRow>
												</MDBCol>
												<MDBCol md="6" bottom className="ps-5 d-none d-md-block">
												<MDBRow className="landing-email-kapcsolat-email modal-form-email-row" >
													<MDBBtn className="tesztvezetes-btn-landing-invers btn-dark shadow-no">
															Test Ride
													</MDBBtn>
													</MDBRow>
												</MDBCol>
												</MDBRow>
												<MDBRow style={{maxWidth: "100%"}}>
												<MDBCol md="6" size="12" className="landing-padding-right">
												<MDBRow className="landing-email-kapcsolat-email landing-form-email-row">
													<label htmlFor="defaultFormContactSubjectEx" className="grey-text">
														<span className="your-email-label">Subject</span>
													</label>
													<input type="text" id="defaultFormContactSubjectEx" name="subject" className="form-control" onChange={this.changeHandler} value={this.state.subject}/>
												</MDBRow>
												{/*}<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
													<label htmlFor="defaultFormContactMessageEx" className="grey-text">
														<span className="your-email-label">Message</span>
													</label>
													<textarea type="text" id="defaultFormContactMessageEx" name="message" className="form-control" rows="3" onChange={this.changeHandler} value={this.state.message}/>
												</MDBRow>*/}
												<MDBRow className="landing-email-kapcsolat-text landing-form-email-row">
						              <label htmlFor="defaultFormContactCompanyNameEx" className="grey-text">
						                <span className="your-name-label">Message</span>
						              </label>
						              <input type="text" id="defaultFormContactCompanyNameEx" name="companyname" className="form-control" onChange={this.changeHandler} value={this.state.companyname} required />
						            </MDBRow>
												<MDBRow className="align-items-top modal-form-checkbox-row">
						              <div className="custom-control custom-checkbox" >
						                <input type="checkbox" className="custom-control-input" id="invalidCheck2" required />
						                <label className="custom-control-label price-page-haufig-text ps-2" htmlFor="invalidCheck2">
						                I accept the provisions of the <a id="1" href="/" target="_blank">Data Management </a> Statement.</label>
						              </div>
												</MDBRow>
												</MDBCol>
												<MDBCol middle md="6">
												<MDBRow className="ps-4">
													<MDBCol middle md="auto" className="ps-2 pe-4">
													<img src={carE4} className="car-e-10"/>
													</MDBCol>
													<MDBCol middle md="auto" className="ps-4 pe-4">
													<img src={green_energy} className="car-e-10"/>
													</MDBCol>
													<MDBCol middle md="auto" className="ps-4 pe-4">
													<img src={bike} className="car-e-10"/>
													</MDBCol>
												</MDBRow>
												</MDBCol>
												</MDBRow>
												<MDBRow style={{maxWidth: "100%", paddingBottom: "36px"}}>
												<MDBCol md="6" size="12" className="landing-padding-right">
												<MDBRow end className="landing-email-kapcsolat-button">
													<MDBBtn className="landing-btn-send-email" type="submit">
															Send
													</MDBBtn>
												</MDBRow>
												</MDBCol>
												<MDBCol md="6" className="ps-5 d-none d-md-block">
												<MDBRow center className="landing-email-kapcsolat-button">
													<MDBCol>
														<MDBRow center className="landing-phonenumber">
															<span className="landing-phonenumber-text">+49 174 344 96 16</span>
														</MDBRow>
													</MDBCol>
													<MDBCol>
														<MDBRow center className="landing-phonenumber">
															<span className="landing-phonenumber-text">info@clicknride.com</span>
														</MDBRow>
													</MDBCol>
												</MDBRow>
												</MDBCol>
													</MDBRow>
											</form>
								</MDBRow>

							</MDBContainer>
							<Footer/>
							</div>
						</div>
					</MDBContainer>


        </ReactFullpage.Wrapper>

				</>
      );
    }}
  />

	{this.state.showModal ?
		<MusterModal
			show={this.state.showModal}
			handleClose={this.handleClose}
			teslamodel={this.state.teslamodel}
			showTesztvezetesModal= {this.state.tesztvezetesmodal ? true : false}
			modalnev= {this.state.tesztvezetesmodal ? "tesztvezetes" : "emailfullname"}
			showEmailFullNameModal= {this.state.tesztvezetesmodal ? false : true}
			{...this.props}/>:
	false}

</>
    );
  }
}
export default  LandingPage;
